import React, { useState, ComponentProps, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { FlexRowAlignCenter } from '@components/wrapper';
import ValidationInput, {
  Input,
  InputLabel,
  InputContainer,
  InputState,
  InputInfo,
  Validation,
} from './ValidationInput';

const MarginedSpan = styled.span({ margin: '0 5px' });

interface Props extends ComponentProps<typeof ValidationInput> {
  separator?: string;
  placeholder?: string[];
  type?: string[];
  list?: string[];
  onChange?: (arg: string) => void;
}

/**
 * 두 input 객체로 이루어져 있지만 하나의 state를 가집니다.
 * 각 input 객체에 입력되는 값과 separtor prop에 지정된 값을 합쳐 state를 업데이트합니다.
 * array 형태로 받는 prop의 경우는 각각 두 input에 순서대로 지정됩니다.
 */
const SplitInput: React.FC<Props> = (props: Props) => {
  const _state: InputState = {
    value: useState(),
    valid: useState(true),
    info: useState(''),
  };

  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');

  const {
    type,
    name,
    label,
    info,
    placeholder,
    list,
    style,
    state = _state,
    validator,
    onChange,
    separator,
    ...rest
  } = props;

  const [value, setValue] = state.value;
  const [valid, setValid] = state.valid;
  const [message, setMessage] = state.info;

  const color: string = valid === false ? '#f300ba' : '#3a3e94';

  const _onChangeValue = async (newValue) => {
    setValue(newValue);

    if (validator) {
      const r = await validator(newValue);
      setValid(r.valid);
      setMessage(r.info);
    }

    if (onChange) onChange(newValue);
  };

  const _onChangeInput1 = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const newInput1 = target.value;
    const newValue = newInput1 + separator + input2;

    setInput1(newInput1);
    _onChangeValue(newValue);
  };

  const _onChangeInput2 = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const newInput2 = target.value;
    const newValue = input1 + separator + newInput2;

    setInput2(newInput2);
    _onChangeValue(newValue);
  };

  return (
    <InputContainer style={style}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      {info ? <InputInfo>{info}</InputInfo> : null}
      <FlexRowAlignCenter>
        <Input
          value={input1}
          valid={valid}
          type={type[0]}
          list={list[0]}
          placeholder={placeholder[0]}
          onChange={_onChangeInput1}
        />
        <MarginedSpan>{separator}</MarginedSpan>
        <Input
          value={input2}
          valid={valid}
          type={type[1]}
          list={list[1]}
          placeholder={placeholder[1]}
          onChange={_onChangeInput2}
        />
        <input type="hidden" name={name} value={value} {...rest} />
      </FlexRowAlignCenter>
      {message && <Validation color={color}>{message}</Validation>}
    </InputContainer>
  );
};

export default SplitInput;
