import React, { FC } from 'react';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

interface props extends LinkProps {
  className?: string;
  disabled?: boolean;
  newWindow?: boolean;
  children?: React.ReactNode;
  onClick?: any;
}

const SpanLink = styled.span`
  cursor: pointer;
`;

/**
 * 기존 a 태그로 영역을 감싸면 전체가 감싸지지 않아서 태깅할 시 문제가 발생 함.
 * display: block 스타일을 추가하여 전체 감싸지도록 컴포넌트 추가
 */
const CustomLink: FC<props> = ({ href, className, disabled, newWindow, children, onClick }) => {
  const styleObj: any = {
    display: 'block',
    pointerEvents: disabled ? 'none' : 'auto',
  };

  /**
   * disable prefetch
   * 1. 새 버전이 배포되었을 때, 바로 적용
   * 2. 중국등 네트워크가 느린 환경에서 과도한 요청
   */
  return (
    <Link href={href} prefetch={false} passHref legacyBehavior>
      <a className={className} style={styleObj} target={newWindow ? '_blank' : '_self'} onClick={onClick}>
        {children}
      </a>
    </Link>
  );
};

/**
 * 기존 a 태그로 영역을 감싸면 전체가 감싸지지 않아서 태깅할 시 문제가 발생 함.
 * display: block 스타일을 추가하여 전체 감싸지도록 컴포넌트 추가
 */
const CustomSpanLink: FC<props> = ({ href, className, disabled, newWindow, children, onClick }) => {
  const router = useRouter();
  const styleObj: any = {
    display: 'block',
    pointerEvents: disabled ? 'none' : 'auto',
  };

  const onClickHandler = onClick || (() => {
    if (newWindow) window.open(href as string, '_blank');
    router.push(href);
  });

  /**
   * disable prefetch
   * 1. 새 버전이 배포되었을 때, 바로 적용
   * 2. 중국등 네트워크가 느린 환경에서 과도한 요청
   */
  return (
    <SpanLink className={'span-link ' + className} style={styleObj} onClick={onClickHandler}>
      {children}
    </SpanLink>
  );
};

export { CustomLink, CustomSpanLink };
