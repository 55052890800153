import styled from 'styled-components';
import { FlexCenterOneItem, FlexRowAlignCenter } from '@components/wrapper';

export const Badge = styled.div`
  position: absolute;
  right: -4px;
  top: -4px;
  width: 4px;
  height: 4px;
  background-color: #4e4eff;
  border-radius: 50%;
`;

export const TextWrapperForBadge = styled.div`
  position: relative;
`;

export const GNBTabContainer = styled(FlexRowAlignCenter)<{ marginLeft: number }>`
  position: relative;

  ${(props) => `
    margin-left: ${props.marginLeft}px;
  `}
  a {
    text-decoration: none;
    transition: 0.1s;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const SubGNBTabContainer = styled(FlexRowAlignCenter)<{ isBadge?: boolean }>`
  position: relative;

  a {
    text-decoration: none;
    transition: 0.1s;
  }
`;

export const CountBadge = styled(FlexCenterOneItem)`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #ffe9fa;
  margin-left: 8px;
`;

export const ArrowIcon = styled.div`
  padding-left: 2px;
`;
