import React from 'react';
import { BaseButton, BaseNoBorderButton } from './styled';

export type IButton = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button = ({ ...rest }) => {
  return <BaseButton {...rest} />;
};

export const NoBorderButton = ({ ...rest }) => {
  return <BaseNoBorderButton {...rest} />;
};
