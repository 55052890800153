/**
 * Devtool 탐지기 장착 + 얼럿 추가
 */
export const alertDeveloperTool = () => {
  import('devtools-detector').then(({ addListener, launch })=> {
    addListener(
      isOpen => {
        if (!isOpen) return;
        alert(
          '개발자도구 사용에대한 모든 법적 책임은 사용자에게 있습니다.\n' +
          'All legal responsibilities for the use of developer tools are with the user.\n' +
          '使用开发者工具的所有法律责任均由用户承担。\n' +
          '開発者ツールの使用に関するすべての法的責任はユーザーにあります。'
        );
      }
    );
    launch();
  });
};
/**
 * 무한 디버거로 방해
 */
export const preventToUseDeveloperTool = ()=> {
  // infinite debugger
  // eslint-disable-next-line no-eval
  setInterval(() => eval('debugger;'), 0);
};
/**
 * 우클릭 방지
 */
export const preventToUseContextMenu = () => {
  document.oncontextmenu = function(){
    return false;
  };
  document.ondragstart = function(){
    return false;
  };
};

/**
 * 콘솔 제거 -> 이건 좀 필요할지도 모르니, 일단 냅둠
 */
export const disableConsoleOutput = () => {
  const console = window.console || {};
  const methods = [
    'log',
    'warn',
    'info',
    'error',
    'exception',
    'table',
    'trace',
  ];
  for (let i = 0; i < methods.length; i += 1) {
    console[methods[i]] = function () {};
  }
};

export const preventAllAbuse = () => {
  if (window.localStorage._CARPENSTREET_ === '떡볶이국물에꼬마김밥') return;
  // alertDeveloperTool();
  preventToUseDeveloperTool();
  preventToUseContextMenu();
  // disableConsoleOutput();
};

export default preventAllAbuse;
