import React, { useEffect } from 'react';
import dateFormat from 'dateformat';
import { useLocalStorage } from 'react-use';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { getKoreaDate } from '@util/time';
import { CustomLink } from '@components/ui';
import { LANGUAGE_CODE } from 'src/constants';
import useResponsiveDevice from '@hooks/useResponsiveDevice';
import { LoginBonusBadgeContainer, LoginBonusBadgeText } from './Gnb.styles';

export const LoginBonusBadge = () => {
  const { t, i18n } = useTranslation();
  const { isReady } = useRouter();
  const currentDate = getKoreaDate();
  const { isMobile } = useResponsiveDevice();
  const [loginBonusDate, setLoginBonusDate] = useLocalStorage<string>('acon-login-bonus', '');
  const isShowLoginBonusBadge =
    loginBonusDate !== dateFormat(currentDate, 'yyyy-mm-dd') &&
    currentDate <= new Date('2023-05-31T23:59:59+09:00') &&
    !isMobile &&
    [LANGUAGE_CODE.KO, LANGUAGE_CODE.EN].includes(i18n.language as LANGUAGE_CODE);

  useEffect(() => {
    if (!isShowLoginBonusBadge || !isReady) return;

    setTimeout(() => {
      setLoginBonusDate(dateFormat(currentDate, 'yyyy-mm-dd'));
    }, 3000);
  }, [isReady]);

  if (!isShowLoginBonusBadge || !isReady) return <div></div>;
  return (
    <CustomLink href={'/event/login-bonus'}>
      <LoginBonusBadgeContainer>
        <LoginBonusBadgeText lineHeight="20px" color={'white'}>
          {t('common:loginBonusBadgeText')}
        </LoginBonusBadgeText>
      </LoginBonusBadgeContainer>
    </CustomLink>
  );
};
