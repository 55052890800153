import React from 'react';
import styled from 'styled-components';
import { colors } from '@public/theme';
import { NoBorderInput } from '@components/ui';

export const SearchContainer = styled.div<React.CSSProperties>`
  background-color: #f8f8f8;
  border: 1px solid ${colors.gray.c1};
  padding: 0px 20px;
  border-radius: 17px;
  height: 34px;
  width: 388px;
  position: relative;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchPlaceholder = styled.div<React.CSSProperties>`
  pointer-events: none;
  position: absolute;
  font-size: 14px;
  color: #7c7c7c;
  line-height: 20px;
  padding-top: ${(props) => `${props.paddingTop}px`};
`;
export const SearchInput = styled(NoBorderInput)`
  flex: 1;
  margin-right: 20px;
  background-color: transparent;
`;
