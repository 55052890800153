import gql from 'graphql-tag';

export const UPLOAD_IMAGE = gql`
  mutation ($key: String!, $name: String!) {
    uploadEditorImage(key: $key, name: $name)
  }
`;

export const UPLOAD_BLOB_STORAGE = gql`
  mutation UPLOAD_BLOB_STORAGE($targetDirectory: String!, $temporaryFilePath: String!, $fileName: String!) {
    uploadBlobStorage(inputs: { targetDirectory: $targetDirectory, temporaryFilePath: $temporaryFilePath, fileName: $fileName }) {
      azureStoragePath
      id
    }
  }
`;

export const SET_PRODUCT_FILE_VIEW = gql`
  mutation ($goodsNo: String!, $lang: String!) {
    setProductFileView(goodsNo: $goodsNo, lang: $lang)
  }
`;

export const UPDATE_GODO_HIT_COUNT = gql`
  mutation ($goodsNo: String!) {
    updateGodoHitCount(goodsNo: $goodsNo)
  }
`;

export const PASSWORD_CHANGE_SEND_EMAIL = gql`
  mutation ($lang: String!, $userId: String!) {
    passwordChangeSendEmail(lang: $lang, userId: $userId)
  }
`;

export const PASSWORD_CHANGE = gql`
  mutation ($key: String!, $password: String!) {
    godoPasswordChange(key: $key, password: $password)
  }
`;

export const SEND_AUTHENTICATION_CODE = gql`
  mutation ($email: String!, $lang: String!) {
    sendAuthenticationCode(email: $email, lang: $lang)
  }
`;

export const AUTHENTICATE = gql`
  mutation ($email: String!, $code: String!) {
    authenticate(email: $email, code: $code)
  }
`;

export const CREATE_FRIEND = gql`
  mutation CREATE_FRIEND($langCode: String!, $godoMemNo: Int!, $code: String!) {
    createFriend(langCode: $langCode, godoMemNo: $godoMemNo, code: $code)
  }
`;

export const CREATE_BUSINESS = gql`
  mutation CREATE_BUSINESS($bizNum: String!, $bizName: String!, $representativeName: String!, $openDate: String!) {
    createBusiness(bizNum: $bizNum, bizName: $bizName, representativeName: $representativeName, openDate: $openDate)
  }
`;

export const ADD_NOTIFICATION = gql`
  mutation ADD_NOTIFICATION($type: String!, $message: String) {
    createAconNotification(type: $type, message: $message) {
      id
      memNo
      type
      message
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DELETE_NOTIFICATION($type: [String!]!) {
    deleteAconNotification(type: $type)
  }
`;

export const CREATE_ORDER_LICENSE = gql`
  mutation CREATE_ORDER_LICENSE($orderNo: String!, $type: String!, $names: [String!]!, $projects: [OrderLicenseProject!]) {
    createOrderLicense(orderNo: $orderNo, type: $type, names: $names, projects: $projects)
  }
`;

export const CREATE_FIRST_HALF_PROMOTION = gql`
  mutation CREATE_FIRST_HALF_PROMOTION($lang: String!) {
    createFirstHalf(lang: $lang)
  }
`;

export const REGISTER_REVIEW = gql`
  mutation REGISTER_REVIEW($memNo: Int!, $goodsNo: Int!, $orderNo: String!, $writerId: String!, $contents: String!, $goodsPt: Int!, $isSecret: String!, $languageCode: String!) {
    registerReview(
      memNo: $memNo
      goodsNo: $goodsNo
      orderNo: $orderNo
      writerId: $writerId
      contents: $contents
      goodsPt: $goodsPt
      isSecret: $isSecret
      languageCode: $languageCode
    ) {
      id
    }
  }
`;

export const UPDATE_REVIEW = gql`
  mutation UPDATE_REVIEW($id: Int!, $memNo: Int!, $goodsNo: Int!, $writerId: String!, $contents: String!, $goodsPt: Int!, $isSecret: String!, $languageCode: String!) {
    updateReview(id: $id, memNo: $memNo, goodsNo: $goodsNo, writerId: $writerId, contents: $contents, goodsPt: $goodsPt, isSecret: $isSecret, languageCode: $languageCode) {
      id
    }
  }
`;

export const FAVORITE_REVIEW = gql`
    mutation SetFavoriteReview($reviewId: Int!) {
        setFavoriteReview(reviewId: $reviewId)
    }
`;

export const REGISTER_REVIEW_V2 = gql`
    mutation RegisterReviewV2($input: RegisterReviewInput!) {
        registerReviewV2(input: $input) {
            id
            created
        }
    }
`;

export const UPDATE_REVIEW_V2 = gql`
    mutation UpdateReviewV2($input: UpdateReviewInput!) {
        updateReviewV2(input: $input) {
            id
        }
    }
`;


//
//
// mutation DeleteMyReview($languageCode: String!, $deleteMyReviewId: Int!) {
// deleteMyReview(languageCode: $languageCode, id: $deleteMyReviewId)
// }
//
// mutation DeleteReviewReply($deleteReviewReplyId: Int!) {
// deleteReviewReply(id: $deleteReviewReplyId)
// }
//
// mutation UpdateReviewReply($reviewId: Int!, $updateReviewReplyLanguageCode2: String!, $contents: String!, $updateReviewReplyId: Int) {
// updateReviewReply(reviewId: $reviewId, languageCode: $updateReviewReplyLanguageCode2, contents: $contents, id: $updateReviewReplyId) {
//
// }
// }
//
//
//
