import React, { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { PAGE_TYPE } from 'src/constants';

const HistoryContext = createContext(null);

function HistoryProvider({ children }) {
  const router = useRouter();
  const { i18n } = useTranslation();
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    if (history.length === 0) setHistory([`${i18n.language}${router.asPath}`]);
    const handleRouteChange = (url: string) => {
      if (!url.includes(PAGE_TYPE.GAME) && !url.includes(PAGE_TYPE.TOON)) return;
      // 10개 까지만 저장하도록
      setHistory((prev) => [url, ...prev].slice(0, 10));
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <HistoryContext.Provider
      value={{
        history,
        setHistory,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
}

export { HistoryProvider, HistoryContext };
