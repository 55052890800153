import styled from 'styled-components';
import { NoBorderInput } from '@components/ui/Input';
import { FlexRowAlignCenter } from '@components/wrapper';
import { colors } from '@public/theme';

export const SearchBox = styled(FlexRowAlignCenter)<{ isGame?: boolean }>`
  background-color: ${(props) => (props.isGame ? '#313135' : '#f8f8f8')};
  border: 1px solid ${(props) => (props.isGame ? '#18181b' : colors.gray.c1)};
  padding: 0px 20px;
  border-radius: 17px;
  height: 34px;
  width: 340px;
  position: relative;
  text-align: left;
  justify-content: space-between;
`;

export const SearchInput = styled(NoBorderInput)<{ isGame?: boolean }>`
  flex: 1;
  margin-right: 20px;
  background-color: transparent;
  ${(props) => props.isGame && 'color: #88888A;'}
`;

export const Placeholder = styled.div<{ paddingTop: number; isGame?: boolean }>`
  pointer-events: none;
  position: absolute;
  font-size: 14px;
  color: ${(props) => (props.isGame ? '#88888A' : '#7c7c7c')};
  line-height: 20px;
  padding-top: ${(props) => `${props.paddingTop}px`};
`;
