import { useEffect, useState } from 'react';
import axios from 'axios';
import { AbortController } from '@azure/abort-controller';
import { BlockBlobClient } from '@azure/storage-blob';
import { useMutation } from '@apollo/client';
import { UPLOAD_BLOB_STORAGE } from '@api/mutation';
import { UploadBlobStorageData, UploadFile } from '@api/blob-storage';

type FileUploadOption = {
  contentType: string;
  ext: string;
  isPublic: boolean;
};

const getFileCredentials = (option: FileUploadOption) => {
  return axios.post(`${process.env.NEXT_PUBLIC_AUTH_URL}/file`, option);
};

export default function useUpload() {
  const [setAzureImage] = useMutation<UploadBlobStorageData>(UPLOAD_BLOB_STORAGE);

  const [controller, setController] = useState<AbortController>(null);

  useEffect(() => {
    const controller = new AbortController();

    setController(controller);
  }, []);

  const cancel = (): void => {
    controller.abort();
  };

  const upload = async (file: File, progressCallback?: (progress: number) => void): Promise<UploadFile> => {
    // 파일 옵션
    const options = {
      // 파일 유형
      contentType: file.type,
      // 확장자
      ext: file.name.split('.').pop(),
      // 공용 여부
      isPublic: true,
    };

    // 파일 URL
    const { data: url } = await getFileCredentials(options);

    const client = new BlockBlobClient(url);

    await client.uploadData(file, {
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
      onProgress: (ev) => {
        const progress = Math.round((100 * ev.loadedBytes) / file.size);
        progressCallback && progressCallback(progress);
      },
    });

    const temporaryFilePath = client.containerName + '/' + client.name;


    const { data } = await setAzureImage({
      variables: {
        targetDirectory: 'inquiry',
        temporaryFilePath,
        fileName: file.name,
      },
    });

    return data.uploadBlobStorage;
  };

  return { upload, cancel };
}
