import React, { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import { Box, Stack } from '@mui/material';
import { GnbContainer, GnbMobileSearchWrapper } from '@components/layout/Header/components/Gnb/Gnb.styles';
import { CustomLink, CustomText, Skeleton } from '@components/ui';
import Logo from 'src/assets/Logo';
import LogoMobile from 'src/assets/LogoMobile';
import useSettings from '@hooks/useSettings';
import { LANGUAGE_CODE, PAGE_TYPE } from 'src/constants';
import LogoBeta from 'src/assets/LogoBeta';
import GnbScrap from 'src/assets/GnbScrap';
import GnbCart from 'src/assets/GnbCart';
import GnbUser from 'src/assets/GnbUser';
import GnbUserArrow from 'src/assets/GnbUserArrow';
import GnbHome from 'src/assets/GnbHome';
import { HoverItem } from '@components/wrapper';
import { CustomIcon } from '@components/ui/Icon';
import useResponsiveDevice from '@hooks/useResponsiveDevice';
import { getKoreaDate } from '@util/time';
import Search from '../Search/Search';
import Grid from '../../../Grid/Grid';
import { GNBTab, SubGNBTab } from '../tab';
import MyPage from '../MyPage/MyPage';
import GnbUserFilled from '../../../../../src/assets/GnbUserFilled';
import { LoginBonusBadge } from './LoginBonusBadge';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Gnb = ({ isMobile, isLogin, isLoginLoading, isCoupon, cartCount }): React.ReactElement => {
  // 번역 도구
  const { t, i18n } = useTranslation(['game', 'menu', 'mode']);
  // 마이페이지 메뉴 표시 여부
  const [isMypageDisplay, setIsMypageDisplay] = useState(false);
  // 모바일일 경우 서치바 토글
  const [toggleSearchBar, setToggleSearchBar] = useState(false);
  // 마이페이지 영역
  const mypageRef = useRef(null);
  const { themeMode } = useSettings();
  const theme = useTheme();
  const { isTablet } = useResponsiveDevice();

  return (
    <GnbContainer height={themeMode === PAGE_TYPE.TOON ? 100 - (isMobile ? 50 : 0) : 80}>
      <Grid>
        <Grid.Item lg={6} md={5} sm={0}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" width="100%" height="100%" sx={{ display: { xs: 'none', sm: 'flex' } }} gap="36px">
            {[LANGUAGE_CODE.KO, LANGUAGE_CODE.EN].includes(i18n.language as LANGUAGE_CODE) ? (
              <>
                <CustomLink href={`/${themeMode}`}>
                  <Logo />
                </CustomLink>
                {[PAGE_TYPE.TOON, PAGE_TYPE.GAME].map((mode) => {
                  const isActive = mode === themeMode;
                  return (
                    <CustomLink key={mode} href={`/${mode}`}>
                      <CustomText
                        size={isMobile ? 14 : 16}
                        lineHeight={isMobile ? '100%' : '24px'}
                        weight={isMobile ? (isActive ? 500 : 400) : 600}
                        style={{ whiteSpace: 'pre' }}
                        color={isActive ? theme.colors.activeText : theme.colors.text}
                        data-mixpanel-action="click"
                        data-mixpanel-evt={`${mode === PAGE_TYPE.TOON ? 'Toon' : 'Game'} Tab Click`}
                      >
                        {t(`mode:${mode}`)}
                      </CustomText>
                    </CustomLink>
                  );
                })}
              </>
            ) : (
              <CustomLink href={'/'}>
                <LogoBeta />
              </CustomLink>
            )}
            {themeMode === PAGE_TYPE.TOON && <Search className="ml-auto grow" />}
          </Stack>
        </Grid.Item>
        <Grid.Item lg={6} md={3} sm={4}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%" height="100%" position={'relative'}>
            {themeMode === PAGE_TYPE.TOON && (
              <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <GNBTab
                  size={isMobile ? 12 : 14}
                  lineHeight={isMobile ? '100%' : '24px'}
                  marginLeft={0}
                  style={{ marginRight: '24px' }}
                  className="menu__about"
                  displayName={t('menu:gnb.about')}
                  href={i18n.language === 'en' || i18n.language === 'zh' ? '/brochure' : '/intro'}
                />
              </Box>
            )}
            {themeMode === PAGE_TYPE.GAME && (
              <HoverItem>
                <CustomLink className={'mr-8'} href={`/${themeMode}/intro/3d-creator`}>
                  <div className={'flex items-center'}>
                    <GnbHome className={'mr-1'} />
                    <CustomText weight={400} color={theme.colors.activeText} style={{ whiteSpace: 'pre' }}>
                      {t('game:upload')}
                    </CustomText>
                  </div>
                </CustomLink>
              </HoverItem>
            )}
            {isLoginLoading ? (
              <Skeleton width={'150px'} height={'14px'} marginLeft={10} />
            ) : isLogin ? (
              <>
                {/* mobile */}
                <Box sx={{ display: { xs: 'flex', sm: 'none' } }} justifyContent="space-between" width="100%">
                  {themeMode === PAGE_TYPE.TOON && toggleSearchBar && (
                    <GnbMobileSearchWrapper>
                      <Search className="mr-5" isMobile />
                      <Box className="cursor-pointer" onClick={() => setToggleSearchBar(false)}>
                        <CustomText color="#313135" weight={600} size={14} style={{ whiteSpace: 'pre' }}>
                          취소
                        </CustomText>
                      </Box>
                    </GnbMobileSearchWrapper>
                  )}

                  <Stack direction="row" alignItems="center" gap="15px">
                    <SubGNBTab
                      href={`/${i18n.language}/category`}
                      iconComponent={<CustomIcon src="/icons/search/mobile-category-icon.png" alt="category" title="category" width="18px" height="12px" />}
                      iconMargin={8}
                      className="gnb__category whitespace-pre"
                      displayName=""
                    />
                  </Stack>
                  <Stack direction="row" alignItems="center" sx={{ position: 'absolute', left: '36px' }}>
                    <CustomLink href={`/${themeMode}`}>
                      <LogoMobile />
                    </CustomLink>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap="15px">
                    {themeMode === PAGE_TYPE.TOON && (
                      <CustomIcon
                        src="/icons/search/mobile_search_icon.png"
                        width="18px"
                        height="18px"
                        onClick={() => setToggleSearchBar(!toggleSearchBar)}
                        className="cursor-pointer"
                      />
                    )}
                    <CustomLink className={'cursor-pointer'} href={'/mypage/cart'}>
                      <GnbCart isMobile={isMobile} count={cartCount} />
                    </CustomLink>
                    <div
                      className={'flex items-center justify-center relative cursor-pointer'}
                      onClick={() => {
                        if (!isMypageDisplay) {
                          setIsMypageDisplay(true);
                        }
                      }}
                      ref={mypageRef}
                    >
                      <GnbUserFilled />
                      <GnbUserArrow isOpen={isMypageDisplay} />
                      {isMypageDisplay && <MyPage isCoupon={isCoupon} onClose={() => setIsMypageDisplay(false)} />}
                    </div>
                  </Stack>
                </Box>
                {/* desktop */}
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  <HoverItem>
                    <CustomLink className={'mr-4 cursor-pointer'} href={'/mypage/clip-book'}>
                      <GnbScrap />
                    </CustomLink>
                  </HoverItem>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <HoverItem>
                    <CustomLink className={'mr-4 cursor-pointer'} href={'/mypage/cart'}>
                      <GnbCart isMobile={isMobile} count={cartCount} />
                    </CustomLink>
                  </HoverItem>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {isTablet ? (
                    <div
                      className={'flex items-center justify-center relative cursor-pointer'}
                      ref={mypageRef}
                      onClick={() => {
                        if (!isMypageDisplay) {
                          setIsMypageDisplay(true);
                        }
                      }}
                    >
                      <GnbUserFilled />
                      <GnbUserArrow isOpen={isMypageDisplay} />
                      {isMypageDisplay && <MyPage isCoupon={isCoupon} onClose={() => setIsMypageDisplay(false)} />}
                    </div>
                  ) : (
                    <div
                      className={'flex items-center justify-center relative cursor-pointer'}
                      ref={mypageRef}
                      onMouseOver={() => setIsMypageDisplay(true)}
                      onMouseOut={() => setIsMypageDisplay(false)}
                    >
                      <HoverItem>
                        <GnbUserFilled />
                        <GnbUserArrow isOpen={isMypageDisplay} />
                      </HoverItem>
                      {isMypageDisplay && <MyPage isCoupon={isCoupon} onClose={() => setIsMypageDisplay(false)} />}
                    </div>
                  )}
                </Box>
              </>
            ) : (
              <>
                {/* mobile */}
                <Box sx={{ display: { xs: 'flex', sm: 'none' } }} justifyContent="space-between" width="100%">
                  {themeMode === PAGE_TYPE.TOON && toggleSearchBar && (
                    <GnbMobileSearchWrapper>
                      <Search className="mr-5" isMobile />
                      <Box className="cursor-pointer" onClick={() => setToggleSearchBar(false)}>
                        <CustomText color="#313135" weight={600} size={14} style={{ whiteSpace: 'pre' }}>
                          취소
                        </CustomText>
                      </Box>
                    </GnbMobileSearchWrapper>
                  )}

                  <Stack direction="row" alignItems="center" gap="15px">
                    <SubGNBTab
                      href={`/${i18n.language}/category`}
                      iconComponent={<CustomIcon src="/icons/search/mobile-category-icon.png" alt="category" title="category" width="18px" height="12px" />}
                      iconMargin={8}
                      className="gnb__category whitespace-pre"
                      displayName=""
                    />
                  </Stack>
                  <Stack direction="row" alignItems="center" sx={{ position: 'absolute', left: '36px' }}>
                    <CustomLink href={`/${themeMode}`}>
                      <LogoMobile />
                    </CustomLink>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap="15px">
                    {themeMode === PAGE_TYPE.TOON && (
                      <CustomIcon
                        src="/icons/search/mobile_search_icon.png"
                        width="18px"
                        height="18px"
                        onClick={() => setToggleSearchBar(!toggleSearchBar)}
                        className="cursor-pointer"
                      />
                    )}
                    <HoverItem>
                      <CustomLink className={'cursor-pointer'} href={'/mypage/cart'}>
                        <GnbCart isMobile={isMobile} count={cartCount} />
                      </CustomLink>
                    </HoverItem>
                    <CustomLink className={'flex items-center justify-center relative cursor-pointer'} href={`/${i18n.language}/users/login`}>
                      <GnbUser />
                    </CustomLink>
                  </Stack>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <HoverItem>
                    <CustomLink className={'mr-4 cursor-pointer'} href={'/mypage/cart'}>
                      <GnbCart isMobile={isMobile} count={cartCount} isLogin={isLogin} />
                    </CustomLink>
                  </HoverItem>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <CustomLink className={'flex items-center justify-center relative cursor-pointer'} href={`/${i18n.language}/users/login`}>
                    <GnbUser />
                  </CustomLink>
                </Box>
              </>
            )}

            <LoginBonusBadge />
          </Stack>
        </Grid.Item>
      </Grid>
    </GnbContainer>
  );
};

export default Gnb;
