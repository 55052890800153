import styled from 'styled-components';
import { colors } from '@public/theme';
import { CloseButton } from '../Button/styled';
import { CustomText } from '../Text';

export const BaseInput = styled.input.attrs(() => ({
  type: 'text',
}))`
  padding: 0;
  background-color: inherit;
  line-height: 100%;
  border: 0;
  font-size: 14px;
  color: #000;
  &:focus {
    outline: none;
  }
`;

export const BorderInput = styled.input`
  border: 1px solid ${colors.gray.c3};
  border-radius: 4px;
  padding: 14px 16px;
  font-size: 12px;
  width: 100%;
  outline: none;
  &:focus {
    border: 1px solid ${colors.gray.c5};
  }
`;

export const NoRoundedInput = styled.input`
  border: 1px solid ${colors.gray.c3};
  padding: 14px 14px;
  font-size: 14px;
  width: 100%;
  outline: none;
  &:focus {
    border: 1px solid ${colors.gray.c5};
  }
`;

export const ClearButton = styled(CloseButton)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  padding: 16px;
  padding-top: 12px;
`;

export const Input = styled.input.attrs({
  type: 'text',
})`
  border: 0;
  margin: 0;
  margin-top: 3px;
  padding: 0;
  height: 14px;
  font-size: 14px;
  flex-shrink: 0;
  flex-grow: 1;
  &:focus {
    outline: none;
  }
`;

export const Placeholder = styled(CustomText).attrs({
  size: 14,
  color: '#7c7c7c',
  weight: 400,
})`
  position: absolute;
`;
