import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

type Props = Omit<IconProps, 'weight' | 'color'>;

export default function ResetIcon({ className, size }: Props) {
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.66014 11.8315C5.63109 10.4448 6.06417 9.08777 6.89116 7.97423C7.71815 6.86068 8.89207 6.05387 10.228 5.68087C11.564 5.30787 12.986 5.38986 14.2703 5.91394C15.5545 6.43803 16.6279 7.37442 17.3214 8.57564"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M17.8137 5.08444L18.1219 8.60652L14.5998 8.91466" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M18.6577 12.4357C18.6868 13.8225 18.2537 15.1795 17.4267 16.293C16.5997 17.4066 15.4258 18.2134 14.0899 18.5864C12.7539 18.9594 11.3318 18.8774 10.0476 18.3533C8.76337 17.8292 7.68997 16.8928 6.99644 15.6916"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6.50415 19.1828L6.19601 15.6607L9.71809 15.3526" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Styled.IconWrapper>
  );
}
