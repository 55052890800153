import React, { ComponentProps } from 'react';
import styled from 'styled-components';

export const ButtonsContainer = styled.div({
  width: 441,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const ConfirmButton = styled.div({
  display: 'inline-block',
  width: 216,
  height: 40,
  boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)',
  padding: '13px 0px',
  textAlign: 'center',
  borderRadius: '3px',
  border: 'none',
  position: 'relative',
  color: 'white',
  backgroundColor: '#333',
  cursor: 'pointer',
  ':hover': { backgroundColor: '#606060' },
  fontSize: 14,
  lineHeight: 1,
  fontWeight: 400,
});

export const CancelButton = styled(ConfirmButton)({
  color: '#333',
  backgroundColor: 'white',
  border: '1px solid #7c7c7c',
  ':hover': { color: '#9e9e9e', backgroundColor: 'white', border: '1px solid #9e9e9e' },
});

interface Props extends ComponentProps<typeof ButtonsContainer>, ComponentProps<typeof ConfirmButton> {
  cancel?: string;
  onCancel?: React.MouseEventHandler;
  confirm?: string;
  onConfirm?: React.MouseEventHandler;
}

const Buttons = ({ cancel, onCancel, confirm, onConfirm, ...rest }: Props) => {
  return (
    <ButtonsContainer {...rest}>
      <CancelButton onClick={onCancel}>{cancel}</CancelButton>
      <ConfirmButton onClick={onConfirm}>{confirm}</ConfirmButton>
    </ButtonsContainer>
  );
};

export default Buttons;
