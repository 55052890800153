import styled from 'styled-components';
import { FlexRow } from '@components/wrapper';

export const Wrapper = styled.div<{ isMobile }>`
  width: 100%;
  height: ${(props) => (props.isMobile ? 40 : 36)}px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: white;
  border-top: ${(props) => (props.isMobile ? '1px solid #e7e7e8' : 'none')};
  overflow-x: overlay;

  @supports (overflow: overlay) {
    overflow-y: overlay;
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
      width: 0;
      border: solid #ffffff 0px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(136, 136, 138);
    }
  }
`;
export const Container = styled(FlexRow)<{ isMobile: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  gap: ${(props) => (props.isMobile ? '20px' : '56px')};
`;

export const CategoryButton = styled.div`
  cursor: pointer;
`;

export const CommonLi = styled.div<{ isActive?: boolean }>`
  position: relative;
  height: 100%;
  display: inherit;
  align-items: inherit;
  ${(props) => props.isActive && 'color: #313135;'}
`;

export const ChatBubbleBadge = styled.div`
  position: absolute;
  top: -26.3px;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid #ffa4ed;
  border-radius: 3px;
  background-color: white;
  padding: 4px 7px;

  &::after {
    content: '';
    position: absolute;
    bottom: -3.2px;
    left: 50%;
    transform: translate(-50%, 0);
    border: 3.3px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    display: block;
    width: 0;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -4.3px;
    left: 50%;
    transform: translate(-50%, 0);
    border: 3.3px solid transparent;
    border-top-color: #ffa4ed;
    border-bottom: 0;
    display: block;
    width: 0;
  }
`;
