import React, { useState } from 'react';
import { AutoNextImage, AutoNextImageContainer } from './styled';

export const AutoImage = (props) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { width, style, ...rest } = props;
  const [isError, setIsError] = useState(props.src.includes('.gif') || false);

  const normalizeSrc = (src) => {
    return src[0] === '/' ? src.slice(1) : src;
  };

  const cloudflareLoader = ({ src, quality }) => {
    if (src.endsWith('.gif')) {
      //  gif 확장자의 경우 최적화 하지 않음
      return src;
    }

    return `https://www.acon3d.com/cdn-cgi/image/f=webp,w=${props.width},q=${quality || 85}/${normalizeSrc(src)}`;
  };

  const placeholder = props.src.startsWith('data:image') ? 'empty' : 'blur';

  return (
    <AutoNextImageContainer style={style}>
      <AutoNextImage
        {...rest}
        lazyBoundary={'600px'}
        placeholder={placeholder}
        blurDataURL={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8vAkAAl4Bh+J6dBIAAAAASUVORK5CYII='}
        onError={() => setIsError(true)}
        onLoadingComplete={props.onLoadingComplete}
        loader={cloudflareLoader}
        src={props.src}
        layout="fill"
        objectFit="contain"
        unoptimized={isError}
      />
    </AutoNextImageContainer>
  );
};
