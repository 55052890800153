import React from 'react';
import { useTranslation } from 'next-i18next';
import CardGroupSkeleton from '@components/common/CardGroup/CardGroupSkeleton';
import { CardGroup } from '@components/common';
import { BestProductType, LanguageCodeEnum, useBestProductsQuery, useProductCardsWithBrandQuery } from '../../../src/generated/graphql';
import { BEST_FILTER_CATEGORY, BEST_TAB } from '../../../src/section/best/constants';
import { PATH_PRODUCT } from '../../../src/routes/paths';

type Props = {
  title: string;
  termValue?: BestProductType;
  isPaidProduct?: boolean;
  categoryCode?: string | null;
  showSeeMore?: boolean;
  isDisplayRankBadge?: boolean;
};

export default function AdminPickCardGroup({
  title,
  termValue = BEST_TAB.WEEKLY,
  categoryCode = BEST_FILTER_CATEGORY.ALL,
  isPaidProduct = true,
  isDisplayRankBadge = false,
  showSeeMore = true,
  ...rest
}: Props): React.ReactElement {
  const { i18n } = useTranslation();
  const bestProduct = useBestProductsQuery({
    variables: {
      type: termValue,
      categoryCode: categoryCode,
      isFreeProductInclude: !isPaidProduct,
    },
  });

  const bestProductNos = bestProduct.data?.bestProducts || [];

  const { data: productCardsData, error } = useProductCardsWithBrandQuery({
    variables: {
      languageCode: i18n.language as LanguageCodeEnum,
      productNos: bestProductNos,
    },
    skip: bestProductNos.length === 0,
  });
  const products = (productCardsData?.productCards || []).filter((x) => x);
  const getProductViewUrl = (val: string) => PATH_PRODUCT.view(val);

  if (!products) return <CardGroupSkeleton />;
  if (error) return <div />;

  return (
    <CardGroup
      title={title}
      source={'/toon/best'}
      products={products.slice(0, 9)}
      getProductViewUrl={getProductViewUrl}
      isDisplayRankBadge={isDisplayRankBadge}
      showSeeMore={showSeeMore}
      {...rest}
    />
  );
}
