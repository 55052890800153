import styled from 'styled-components';
import { FlexRow } from '@components/wrapper';

export const Wrapper = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  z-index: 11;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #e3e3e3;
`;

export const Container = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  width: 900px;
`;

export const CategoryButton = styled.div`
  cursor: pointer;
`;

export const CommonLi = styled.div<{ isActive?: boolean }>`
  position: relative;
  height: 100%;

  ${(props) => props.isActive && 'border-bottom: 2px #000000 solid;'}
`;

export const ChatBubbleBadge = styled.div`
  position: absolute;
  top: -26.3px;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid #ffa4ed;
  border-radius: 3px;
  background-color: white;
  padding: 4px 7px;

  &::after {
    content: '';
    position: absolute;
    bottom: -3.2px;
    left: 50%;
    transform: translate(-50%, 0);
    border: 3.3px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    display: block;
    width: 0;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -4.3px;
    left: 50%;
    transform: translate(-50%, 0);
    border: 3.3px solid transparent;
    border-top-color: #ffa4ed;
    border-bottom: 0;
    display: block;
    width: 0;
  }
`;
