import React, { useState } from 'react';
import { CustomImage, CustomLink } from '@components/ui';
import { TActionName } from '../../CardGrid/type';
import { CardScrap, ImageWrapper, MainImage, Skeleton, SubImage } from './styled';

interface CardImageInfo {
  mainImageURL: string;
  subImageURL: string;
  imageWidth?: number;
  imageHeight?: number;
  href: string;
  title: string;
  goodsNo: string;
  wishSno: string;
  isDisplayOnScrap: boolean;
  isScrapEdit: boolean;
  isDisabled?: boolean;
  onSale?: boolean;
  onClick?: (actionName: TActionName) => void;
}

export const CardImg: React.FC<CardImageInfo> = ({
  mainImageURL,
  subImageURL,
  imageWidth = 274,
  imageHeight = 180,
  href,
  title,
  goodsNo,
  wishSno,
  isDisplayOnScrap,
  isScrapEdit,
  onSale = true,
  onClick,
}) => {
  // 이미지 스켈레톤 여부
  const [isSkelton, setIsSkelton] = useState(true);

  const handleImageWrapperClick = () => onClick('image');

  // Card 이미지 로드 된 후 스켈레톤 UI 제거
  const handleLoad = () => {
    // 스켈레톤 UI 제거
    setIsSkelton(false);
  };

  return (
    <ImageWrapper isDisplayOnScrap={isDisplayOnScrap} imageHeight={imageHeight} isScrapEdit={isScrapEdit} onClick={handleImageWrapperClick}>
      <CustomLink href={href} newWindow>
        <MainImage>
          <CustomImage src={mainImageURL} width={imageWidth} height={imageHeight} backgroundColor={'white'} alt={title} objectFit={'cover'} onLoad={handleLoad} />
        </MainImage>
        <SubImage>
          <CustomImage src={subImageURL} width={imageWidth} height={imageHeight} backgroundColor={'white'} alt={title} objectFit={'cover'} />
        </SubImage>
      </CustomLink>
      {!isDisplayOnScrap && !isScrapEdit && <CardScrap isDisabled={!onSale} wishSno={wishSno} goodsNo={goodsNo} legacy={true} />}
      {isSkelton && <Skeleton />}
    </ImageWrapper>
  );
};
