export const statusCodes = {
  400: 'Bad Request',
  401: 'Forbidden or Unauthorized',
  404: 'This page could not be found',
  500: 'Internal Server Error',
  503: 'Site Maintenance',
  client: 'Client Error',
};

export const simplyStatusCode = (codeProps = -1) => {
  const code = Number(codeProps);
  if (code <= 0) return 400;
  if (code === 404) return 404;
  if (code === 503) return 503;
  if (code % 400 < 100) return 400;
  if (code % 500 < 100) return 500;
  return code;
};


export const DONT_SEND_ERRORBOUNDARY_ERROR = [
  'Warning',
  'Minified React error',
  'Abort fetching',
  'Invariant',
  'Hydration',
];
