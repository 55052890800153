import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { CardGroup } from '@components/common';
import { convertLangCodeForDB } from '@lib';
import { mainProductTimerSno } from 'src/constants';
import * as ga from '@lib/ga';
import { LanguageCodeEnum, useProductCardsWithBrandQuery, useMainProductListV2Query } from 'src/generated/graphql';
import { PATH_PRODUCT } from 'src/routes/paths';
import { productClickEventLog } from '@lib/mixpanel/events';
import { ICardClickProps } from '../Card/type';
import { default as CardGroupSkeleton } from './CardGroupSkeleton';

type Props = {
  sno: number;
  showSeeMore?: boolean;
  isDisplayRankBadge?: boolean;
  eventPageName?: string;
};

export default function SnoCardGroup({ sno, isDisplayRankBadge = false, showSeeMore = true, eventPageName, ...rest }: Props) {
  const { i18n } = useTranslation();
  const { data, error } = useMainProductListV2Query({
    variables: {
      lang: convertLangCodeForDB(i18n.language),
      sno,
    },
  });
  const mainProductListData = data?.mainProductListV2 || null;
  const productNos = mainProductListData?.productList || [];

  const { data: productCardsData } = useProductCardsWithBrandQuery({
    variables: {
      productNos: productNos,
      languageCode: i18n.language as LanguageCodeEnum,
    },
    skip: productNos.length === 0,
  });
  const mainProducts = (productCardsData?.productCards || []).filter((x) => x.onDisplay);
  const getProductViewUrl = (val: string) => PATH_PRODUCT.view(val, sno, mainProductListData?.title);

  useEffect(() => {
    if (!productCardsData) return;

    const mainProducts = (productCardsData.productCards || []).filter((x) => !x.onDisplay);
    if (mainProducts.length === 0) return;
    ga.impressionsCardGroupEvent(
      mainProducts.map((x, index) => {
        return {
          index,
          sno: sno.toString(),
          goodsNo: x.id.toString(),
          title: x.title,
          brandCd: x.brand.code,
          price: x.price,
        };
      }),
    );
  }, [productCardsData]);

  if (!data) return <CardGroupSkeleton />;

  if (error || !mainProductListData || mainProducts.length === 0) return <div />;

  const handleCardClick = ({ actionName, goodsNo, title }: ICardClickProps, order: number) => {
    productClickEventLog({
      pageName: eventPageName,
      actionName: actionName,
      productName: title,
      productNo: goodsNo,
      groupSno: sno,
      groupTitle: mainProductListData?.title,
      order: order,
    });
  };

  return (
    <CardGroup
      title={mainProductListData.title}
      source={mainProductListData.source}
      products={mainProducts}
      getProductViewUrl={getProductViewUrl}
      isPromotionTimer={mainProductTimerSno === sno}
      isDisplayRankBadge={isDisplayRankBadge}
      showSeeMore={showSeeMore}
      handleCardClick={handleCardClick}
      {...rest}
    />
  );
}
