import React, { useEffect } from 'react';
import parse, { Element } from 'html-react-parser';
import ImageMap from 'image-map';
import { downloadCoupon } from '@lib';
import * as Styled from './styled';

type props = {
  color: string;
  bannerImageUrl: string;
  bannerMap?: string;
  isBorder?: boolean;
  borderColor?: string;
};

export const PromotionBannerItem: React.FC<props> = ({ color, isBorder, borderColor, bannerImageUrl, bannerMap }) => {
  useEffect(() => {
    ImageMap('img[usemap]');
  }, [bannerMap]);
  const parseMapElement = (mapElement) => {
    return parse(mapElement, {
      replace: (domNode) => {
        if (domNode instanceof Element) {
          if (domNode.attribs.href?.includes('couponDownload(')) {
            //  고도몰에서 사용하던 방식의 쿠폰다운로드 함수 실행이 있을 경우 v2 버전함수로 변환
            const attributes = { ...domNode.attribs };
            const couponKey = parseInt(attributes.href.split('(').pop().split(')').shift().replace(/['"]+/g, ''));

            attributes.href = '#none';

            return <area {...attributes} onClick={() => downloadCoupon(couponKey)} />;
          }
        }
      },
    });
  };

  return (
    <Styled.PromotionBannerContainer color={color} isBorder={isBorder} borderColor={borderColor}>
      <Styled.PromotionBannerCenter>
        <Styled.PromotionBannerImage src={bannerImageUrl} title="categoryBanner" alt="categoryBanner" useMap="#eventBanner" />
        {bannerMap && parseMapElement(bannerMap)}
      </Styled.PromotionBannerCenter>
    </Styled.PromotionBannerContainer>
  );
};
