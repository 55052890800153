import styled, { keyframes } from 'styled-components';
import { CustomImage } from '@components/ui';
import { colors } from '@public/theme';

export const Wrapper = styled.div<{ isBorder: boolean; bannerHeight: number }>`
  width: 100%;
  overflow: hidden;
  ${(props) => props.isBorder && `border: 1px ${colors.gray.c1} solid;`}
  ${(props) => `height: ${props.bannerHeight ? props.bannerHeight : 0}px;`}

  & .slick-active {
    z-index: 10;
  }
`;

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

export const BannerSkelton = styled.div`
  width: 100%;
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: 0px 0px;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;

  ${(props) =>
    `height: 400px;
         background-image: linear-gradient(lightgray ${props['data-height']}px, transparent 0);
        `}
`;

export const BannerContainer = styled.div<{ color: string }>`
  ${(props) => `background-color: ${props.color};`}
`;

export const BannerContents = styled.div<{ bannerWidth: string }>`
  margin: 0 auto;
  height: 100%;

  ${(props) => `
        width: ${props.bannerWidth}px;
    `}

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const PagingWrapper = styled.ul<{ bottom: number }>`
  margin: 0px;
  text-align: center;

  & > li {
    width: auto;
  }

  ${(props) => `
        bottom: ${Number(props.bottom) - 1}px;
    `}
`;

export const PagingItem = styled.li<{ active: boolean; activeColor: string }>`
  list-style: none;
  opacity: 0.75;

  & > div {
    ${(props) => `
            ${
  props.active &&
              `
                opacity: 1;
                background-color: ${props.activeColor};
            `
};
        `}
  }
`;

export const PagingButton = styled.div<{ size: number; color: string; radius: string }>`
  ${(props) => `
        border-radius: ${props.radius};
        background-color: ${props.color};
        width: ${props.size}px;
        height: ${props.size}px;
    `}
`;

export const ArrowPrev = styled.div`
  z-index: 1;
  width: 80px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  left: 0px;
  opacity: 0.7;

  &::before {
    display: none;
  }
`;

export const ArrowNext = styled.div`
  z-index: 1;
  width: 80px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  right: 0px;
  opacity: 0.7;

  &::before {
    display: none;
  }
`;

export const GameBannerContainer = styled.div`
  width: 100%;
  height: 340px;
  background-color: #3c3d41;
  display: flex;
  justify-content: center;
`;

export const GameBannerImage = styled(CustomImage)`
  width: 900px;
  height: 100%;
`;
