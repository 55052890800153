import { checkCerification, getCertificationId, registerGlobalAdult } from '@api/payment/payment';
import { CertificationError } from '@lib/error/certification-error';
import { LanguageCodeEnum } from 'src/generated/graphql';

export type TCertificateStatus = 'confirmed' | 'rejected' | 'verifying';

/** [todo]: 이거 변경되었을 텐데, 수정필요 */
interface ICertificationResult {
  aconMemberId: number;
  tid: string;
  status: TCertificateStatus;
  comfirmedAt: string;
  createdAt: string;
  rejectedAt: string;
}

export interface IAdultCertificationProps {
  languageCode: string;
  dob?: string;
}

const danalCertificationURL = 'https://wauth.teledit.com/Danal/WebAuth/Web/Start.php';

const windowCenterValue = (width: number, height: number) => {
  const top = (window.outerHeight - height) / 2;
  const left = (window.outerWidth - width) / 2;

  return [top, left];
};

const openCertificationModal = (certifictionTid: string, width: number, height: number) => {
  const createHiddenInput = (name: string, value: string) => {
    const newInput = document.createElement('input');
    newInput.setAttribute('type', 'hidden');
    newInput.setAttribute('name', name);
    newInput.setAttribute('value', value);
    return newInput;
  };

  const [top, left] = windowCenterValue(width, height);
  const newForm = document.createElement('form');
  newForm.setAttribute('action', danalCertificationURL);
  newForm.setAttribute('method', 'post');

  newForm.appendChild(createHiddenInput('TID', certifictionTid));
  newForm.appendChild(createHiddenInput('BgColor', '00'));
  newForm.appendChild(createHiddenInput('IsCharSet', 'EUC-KR'));
  newForm.appendChild(createHiddenInput('ByBuffer', 'This value bypass to CPCGI Page'));
  newForm.appendChild(createHiddenInput('ByAnyName', 'Anyvalue'));

  const newWindow = window.open('about:blank', '', `height=${height},width=${width},location=no,status=yes,dependent=no,scrollbars=yes,resizable=yes,top=${top},left=${left}`);
  newWindow.document.body.appendChild(newForm);
  newWindow.document.body.getElementsByTagName('form')[0].submit();
  newWindow.focus();

  return newWindow;
};

/** 본인인증 모달 표시 및 인증 처리  */
export const certification = (languageCode: LanguageCodeEnum): Promise<ICertificationResult> => {
  return new Promise((resolve, reject) => {
    const errorHandler = (err: Error | any) => {
      const isCertificationError = err instanceof CertificationError;
      err.customMessage = isCertificationError ? err.message : '본인확인 처리 중 오류가 발생하였습니다.';
      err.message = `본인 확인 처리 중 오류 발생 (${isCertificationError ? err.loggingMessage : err.message})`;
      reject(err);
    };
    try {
      const detectCloseWindow = (thisWindow: Window, resolve: (value: unknown) => void) => {
        if (thisWindow?.closed) {
          checkCerification(languageCode).then((result) => {
            resolve(result);
          });
          return;
        }

        setTimeout(() => detectCloseWindow(thisWindow, resolve), 300);
      };

      getCertificationId().then(certifictionTid => {
        const newWindow = openCertificationModal(certifictionTid, 440, 720);
        detectCloseWindow(newWindow, resolve);
      }).catch(errorHandler);
    } catch (err) {
      errorHandler(err);
    }
  });
};

/** 본인인증을 이용한 성인인증 처리  */
export const adultCertification = async (props: IAdultCertificationProps): Promise<ICertificationResult | null> => {
  try {
    const languageCode = props.languageCode as LanguageCodeEnum;
    if (props.languageCode !== LanguageCodeEnum.Ko) return await registerGlobalAdult(languageCode, props.dob);
    return await certification(languageCode);
  } catch (err) {
    alert(err.customMessage || '본인확인 처리 중 오류가 발생하였습니다.');
    throw new Error(err);
  }
};
