function padZero(num) {
  return num.toString().padStart(2, '0');
}

function getLocalTimezoneDate(offsetInMinutes) {
  const dateInTimezone = new Date(Date.now() + offsetInMinutes * 60000);
  const year = dateInTimezone.getUTCFullYear();
  const month = padZero(dateInTimezone.getUTCMonth() + 1);
  const date = padZero(dateInTimezone.getUTCDate());
  const hours = padZero(dateInTimezone.getUTCHours());
  const minutes = padZero(dateInTimezone.getUTCMinutes());
  return `${year}${month}${date}${hours}${minutes}`;
}

function getKoreaDate() {
  const utcCurrentDate = new Date();
  const utc = utcCurrentDate.getTime() + utcCurrentDate.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  return new Date(utc + KR_TIME_DIFF);
}

function getKoreaDateStringFormat(offsetInMinutes) {
  const utcCurrentDate = new Date(Date.now() + offsetInMinutes * 60000);
  const utc = utcCurrentDate.getTime() + utcCurrentDate.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const koreaDate = new Date(utc + KR_TIME_DIFF);

  const year = koreaDate.getFullYear();
  const month = padZero(koreaDate.getMonth() + 1);
  const date = padZero(koreaDate.getDate());
  const hours = padZero(koreaDate.getHours());
  const minutes = padZero(koreaDate.getMinutes());
  return `${year}${month}${date}${hours}${minutes}`;
}

function getLastDateOfCurrentMonth(month = null) {
  const current = new Date();
  const year = current.getFullYear();
  const currentMonth = current.getMonth() + 1;
  return new Date(year, month ? month : currentMonth, 0).getDate();
}

function getFirstDayOfCurrentMonth(month = null) {
  const current = new Date();
  const year = current.getFullYear();
  const currentMonth = current.getMonth();
  return new Date(year, month ? month : currentMonth, 1).getDay();
}

module.exports = { getLocalTimezoneDate, getKoreaDate, getKoreaDateStringFormat, getLastDateOfCurrentMonth, getFirstDayOfCurrentMonth };
